import React, { useEffect } from "react";
import "material-icons/iconfont/material-icons.css";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.min.js";
import "react-image-crop/dist/ReactCrop.css";
import "react-datetime/css/react-datetime.css";
import "react-toastify/dist/ReactToastify.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import App from "./app";
import { Signup } from "../services/SEOServices";
import { useDispatch } from "react-redux";
import { HeaderSEO } from "../store/Action/Action";

const IndexPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    Signup()
      .then((response) => {
        dispatch(HeaderSEO(response.data.data));
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);
  return <App />;
};

export default IndexPage;
